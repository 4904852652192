import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/database'

import 'antd/dist/antd.css'

import App from './App'
import PortalCity from './PortalCity'
import * as serviceWorker from './serviceWorker'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyChDmuJkGGrFljBMdPTCIIaPibSdsL5kUk",
  authDomain: "zoom-stream.firebaseapp.com",
  databaseURL: "https://zoom-stream.firebaseio.com",
  storageBucket: "zoom-stream.appspot.com",
}
firebase.initializeApp(config);
class Index extends React.Component {
  render () {
    return (
      <Router>
        <div>
          <Route exact path='/' name='home' component={App} />
          <Route exact path='/portal/:city' component={PortalCity} />
        </div>
      </Router>
    )
  }
}

ReactDOM.render(<Index />, document.getElementById('root'))
serviceWorker.unregister()
