import React, { Component } from 'react'
import { Layout } from 'antd'

const { Header } = Layout

class HeaderMain extends Component {
  render () {
    return (
      <Header>
        <div className="logo" >
          <img src={require('./../assets/images/zoom.png')} width='10%' alt='zoom logo'/>
        </div>
      </Header>
    )
  }
 }

export default HeaderMain