import React, { Component } from 'react'
import moment from 'moment'
import { Layout } from 'antd'

const { Footer } = Layout

class FooterMain extends Component {
  render () {
    return (
      <Footer>©  We Dev Ideas - {moment().format('YYYY')}</Footer>
    )
  }
 }

export default FooterMain