/* eslint-disable */
import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import firebase from 'firebase/app'
import 'firebase/database'
import Player from './elements/Player'

const streams = require('./zoomStream.json')

class PortalCity extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cityCam: '',
      camAdd: ''
    }
    this.setCams = this.setCams.bind(this)
  }

  setCams(camCount, camerasCity) {
    let cameras = Object.keys(camerasCity)
    let c = camCount % cameras.length
    let camera = camerasCity[cameras[c]]
    this.setState({camAdd: camera.cam})
    setTimeout(() => this.setCams(camCount + 1, camerasCity), camera.delay) 
  }
  
  componentDidMount () {
    if (this.state.cityCam === '') {
      const city = this.props.match.params.city
      this.setState({cityCam: streams[city]})
      this.setCams(0, streams[city])
       /*firebase.database().ref(city).on('value', (snapshot) =>{
        this.setState({cityCam: snapshot.val()})
        this.setCams(0, snapshot.val())
      })*/
    }
  }

  renderPortal () {
    return (
      <div className="video">
        <Player sourcePath={this.state.camAdd} className="embed-responsive-item"/>
      </div>
    )
  }

  render () {
    return this.state.cityCam === '' ? <div className='bk'><ReactLoading type={'spin'} color={'#b0d137'} height={'5%'} width={'5%'} /></div> : this.renderPortal()
  }

}

export default PortalCity