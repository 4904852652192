import React from 'react'
import Clappr from 'clappr'
import './../assets/css/camStyle.css'

class Player extends React.Component {

    componentDidMount() {
        // build player config
        const playerConfig = {
            source: this.props.sourcePath,
            parentId: '#player',
            autoPlay: true,
            width: '100%',
            height: '100%',
            hlsjsConfig: {
              // here any hls.js configuration options
            }
        };
        this.player = new Clappr.Player(playerConfig);
        // bind events
        const Events = Clappr.Events;
        this.player.on(Events.PLAYER_ERROR, console.error);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sourcePath !== this.props.sourcePath) {
            this.player.load([nextProps.sourcePath]);
        }
    }

    componentWillUnmount() {
        this.player.destroy();
        this.player = null;
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return <div className="pl" id='player'/>
    }
}

export default Player