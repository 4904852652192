import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './assets/css/styles.css'
import { Button, Layout, Row, Col } from 'antd'

import Footer from './elements/FooterMain'
import Header from './elements/HeaderMain'

const { Content } = Layout


class App extends Component {
  render() {
    return (
      <Layout>
        <Header />
        <Content style={{ padding: '0 50px' }}>
          <div className='btnGroup gutter-example'>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box">
                <Link to='/portal/SP'><Button type="primary" >SP</Button></Link>
                </div>
              </Col>
              <Col className="gutter-row" span={12} >
                <div className="gutter-box">
                <Link to='/portal/RJ'><Button type="primary">RJ</Button></Link>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer />
    </Layout> 
     
    );
  }
}

export default App
